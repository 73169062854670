/* ==========================================================================
   PRIMARY NAV
   ========================================================================== */
  header.banner {
    background: white;
  }
  nav.navbar {
    background-color: #fff;
  }
  .navbar-toggler {
  	z-index: 10;
  	color: $brand-primary;
    top: 1rem;
  }
  .navbar-brand {
  	color: $gray;
    width: 250px;
    img {
      @include media-breakpoint-down(sm) { 
        width: 50%;
      }
    }
  }
  .navbar-nav {
  	@include media-breakpoint-up(lg) { 
  		float: right;
  	}
  }
  .nav {
  	li {
  		@extend .nav-item;
      text-transform: uppercase;
      position: relative;
      &.active, &:hover {
        a {
          color: $brand-primary;
          border-bottom: 1px solid $brand-primary;
        }
        .sub-menu {
          li,
          li.active {
            a {
              border-bottom: none;
            }
          }
        }
      }
      a {
        @extend .nav-link;
        border-bottom: 1px solid transparent;
        font-size: 14px;
        font-weight: 700;
  			color: $brand-primary;
				text-align: center;
				letter-spacing: 1px;
  		}
      .sub-menu {    
        @extend .list-unstyled;
        z-index: 1;
        background: white;
        li {
          text-transform: capitalize;
          border-bottom: 1px solid transparent;
          &:hover {
            border-bottom: 1px solid $brand-primary;
          }
        }
      }
      @include media-breakpoint-up(md) { 
        &.menu-item-has-children {
          .sub-menu {
            display: none;
            position: absolute;
            width: 150%;
            left: -25%;
          }
          &:hover {
            .sub-menu {
              display: block;
            }
          }
        }
      }
  	}
  }
  .super-nav {
    .nav {
      li { 
        a {
          font-size: 12px;
          color: $gray-light;
          font-weight: 400;
        }
      }
    }
  } // .super-nav