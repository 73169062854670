/* ==========================================================================
   HEADINGS
   ========================================================================== */
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
  	font-weight: 700;
  }
/* ==========================================================================
   CONTENT
   ========================================================================== */
    #content p a {
      color: #0071EB;
      text-decoration: underline;
    }
/* ==========================================================================
   MODAL STYLES
   ========================================================================== */
   .modal-content {
      -webkit-box-shadow: none;
      box-shadow: none;
      border:none;
      outline:none;
      border-radius: 0;
      &.video {
        background:transparent;
      }
  }
  .modal-content iframe {
      border:none;
      padding:0;
      margin:0;
  }
  .close {
      font-size: 40px;
      margin:-20px 0 0 0;
  }
  .modal-body {
    padding: 15px 30px 30px 30px;
  }

  #skip-to-content {
    a {
      background: $brand-primary;
      border: 1px solid white;
      color: white;
      font-size: 1.5rem;
      padding: 0.5rem 1rem;
      position: absolute;
      top: -100px;
      left: 1rem;
      transition: top 1s ease-out;
      z-index: $zindex-tooltip;
  
      &:focus {
        position: absolute;
        top: 0;
        transition: top 0.1s ease-in;
      }
    }
  }

  /***** MEDIA QUERIES *****/
   @media only screen and (max-width: 641px) {
      /***** MODAL PROPERTIES *****/
      .modal-body {
          margin: 0;
      }
      .modal-content {
          padding:0;
          margin: 0;
          &.video {
            .modal-body {
              height:100px;
              padding:0;
            }
          }
      }
      .modal-dialog {
          position: relative;
          width: auto;
          margin: 15px;
      }
      .close {
          margin:-12px 0 0 0;
      }
  }
  @media only screen and (min-width: 768px) {
      /***** MODAL PROPERTIES *****/
      .close {
          font-size: 60px;
          margin:30px -43px -20px 100px;
      }
      #labelModal .close,
      .info-modal .close {
        margin: -10px 20px;
      }
  }