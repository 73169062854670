.entry-title {
	a {
		color: $brand-secondary;
		&:hover {
			text-decoration: none;
			color: darken($brand-secondary,10%);
		}
	}
	time {
		color: $gray-light;
		font-size: 50%;
    @include media-breakpoint-up(md) { 
			float: right;
    }
	}
}