// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #3565a7;
$brand-secondary:				#b8476d;
$gray:									#97999C;
$gray-light:  #636c72;
$gray-lighter: #ddd;
$gray-dark: #333;
$gray-medium: #757575;

	$twitter:     #00aced;
	$facebook:    #3b5998;
	$googleplus:  #dd4b39;
	$pinterest:   #cb2027;
	$linkedin:    #007bb6;
	$youtube:     #bb0000;
	$vimeo:       #aad450;
	$tumblr:      #32506d;
	$instagram:   #517fa4;
	$flickr:      #ff0084;
	$dribbble:    #ea4c89;
	$quora:       #a82400;
	$foursquare:  #0072b1;
	$forrst:      #5B9A68;
	$vk:          #45668e;
	$wordpress:   #21759b;
	$stumbleupon: #EB4823;
	$yahoo:       #7B0099;
	$blogger:     #fb8f3d;
	$soundcloud:  #ff3a00;
	$snap:        #fffc00;
/* ==========================================================================
   STYLES TO EXTEND
   ========================================================================== */
  .center-vertical {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    &.offset {
      top: 55%;
      transform: translateY(-55%);
    }
  }

/* ==========================================================================
   TYPOGRAPHY
   ========================================================================== */
  $headings-font-family: 'myriad-pro';
  $font-family-sans-serif: 'myriad-pro';

/* ==========================================================================
   MIXINs
   ========================================================================== */
  @mixin background-image-retina($file, $type, $width, $height) {
    background-image: url('../../assets/images/'+$file + '.' + $type);
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (-moz-min-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx){
      & {
        background-image: url('../../assets/images/'+$file + '@2x.' + $type);
        -webkit-background-size: $width $height;
        -moz-background-size: $width $height;
        -o-background-size: $width $height;
        background-size: $width $height;
      }
    }
    /**
     * USEAGE
     * .class {
       * width: 200px;
       * height: 100px;
       * @include background-image-retina(logo, png, 200px, 100px);
       * // Image replacement code 
       * display: block; 
       * text-indent: -9999px; 
       * white-space: nowrap; 
       * overflow: hidden; 
     * }
     */
  }