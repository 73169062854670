// Search form
.search-form label,
.gfield {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field,
.gfield input,
.gfield textarea,
.gfield select {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
.gform_button.button {
	@extend .btn;
	@extend .btn-primary;
}
