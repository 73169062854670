/**
 * Description: Short description.
 * Version: 1.0.0
 * Last update: YYYY/MM/DD
 * Author: User Name <username@gmail.com>
 *
 * Summary:
 *
 *	0. HOMEPAGE
 *		- 0.1. HOME BANNER
 *		- 0.2. HOME ABOUT
 *    - 0.3. HOME SERVICES
 *    - 0.4. HOME DONATE
 *    - 0.5. LATEST POSTS
 *	1. SUB PAGES
 *		- 1.1. TYPE ELEMENT
 *		- 1.2. TYPE ELEMENT
 */

/* ==========================================================================
   0. HOMEPAGE
   ========================================================================== */

/* 0.1. HOME BANNER
   ========================================================================== */
  #page-banner {
  	position: relative;
  	z-index: 0;
  }

/* 0.2. HOME ABOUT
   ========================================================================== */
  #home-about {
    img {
      z-index: 1;
      position: relative;
      @include media-breakpoint-up(md) { 
        margin-top: -8%;
        margin-bottom: .25rem !important;
      }
    }
    h1 {
      color: $brand-secondary;
    }
    p {
      @extend .lead;
    }
    #content {
      margin-top: 0.25rem!important;
      margin-bottom: 0.25rem!important;
    }
  }
/* 0.3. HOME SERVICES
   ========================================================================== */
  #home-services {
  	color: white;
  	background: $brand-primary;
  	border-bottom: 10px solid white;
  	.card {
  		border: none;
  	}
  	.service-bucket {
  		background: transparent;
  		margin-right: 0;
  		margin-bottom: 30px;
  		margin-left: 0;
  		border-radius: 0;
  		.card-footer {
  			background: transparent;
  			border-radius: 0;
  			border: none;
  		}
  		img {
  			border: 2px solid white;
  			margin-bottom: 30px;
        max-width: 125px;
  		}
      @include media-breakpoint-up(md) { 
	  		border-right: 5px solid white;
	  		border-left: 5px solid white;
	  		margin-bottom: 0;
	  		img {
	  			margin-top: -50px;
	  		}
	  		&:first-child {
	  			border-left: 10px solid white;
	  		}
	  		&:last-child {
	  			border-right: 10px solid white;
	  		}
  		}
      a {
        color: $brand-primary;
        display: block;
        i {
          font-size: 75%;
        }
      }
  	}
  }
/* 0.4. HOME DONATE
   ========================================================================== */
  #home-donate {
  	color: white;
  	background: $brand-secondary;
    h2 {
      color: white !important;
    }
    a {
      color: $brand-secondary;
    }
  }
/* 0.5. LATEST POSTS
   ========================================================================== */
  #latest-posts {
  	.media {
  		img {
  			max-width: 125px;
  			height: auto;
  		}
  	}
  	article {
  		a {
  			text-decoration: none;
        font-weight: 700;
  		}
  		h5 a {
  			color: $gray-light;
  			&:hover,
  			&:active,
  			&:focus {
  				text-decoration: none;
  				color: $gray-dark;
  			}
  		}
  		p {
  			font-size: 14px;
        color: $gray-light;
  		}
  	}
  }

/* ==========================================================================
   1. SUB PAGES
   ========================================================================== */
  .page, 
  .blog {
    #content {
      h2 {
        color: $brand-primary;
      }
    }
    #parent-voices-carousel {
      min-height: 150px;
      p, i {
        color: $brand-primary;
      }
    }
  }

/* ==========================================================================
   2. BLOG
   ========================================================================== */
  .blog {
    article.article-other {
      h4 a {
        color: $gray-light;
        &:hover {
          text-decoration: none;
          color: $gray;
        }
      }
      .media {
        img {
          max-width: 150px;
          height: auto;
        }
      }
    }
  }
