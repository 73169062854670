footer#sponsors {
  position: relative;
  img {
    max-width: 100%;
  }
  .carousel {
    @include media-breakpoint-up(xs) { 
      height: 85px;
    }
    @include media-breakpoint-up(sm) { 
      height: 90px;
    }
    @include media-breakpoint-up(md) { 
      height: 130px;
    }
    @include media-breakpoint-up(lg) { 
      height: 230px;
    }
  }
  .carousel-inner {
    @include media-breakpoint-up(xs) { 
      height: 85px;
    }
    @include media-breakpoint-up(sm) { 
      height: 90px;
    }
    @include media-breakpoint-up(md) { 
      height: 130px;
    }
    @include media-breakpoint-up(lg) { 
      height: 230px;
    }
    .row {
      @include media-breakpoint-up(xs) { 
        height: 85px;
      }
      @include media-breakpoint-up(sm) { 
        height: 90px;
      }
      @include media-breakpoint-up(md) { 
        height: 130px;
      }
      @include media-breakpoint-up(lg) { 
        height: 230px;
      }
    }
  }
  .case-study-container {
    display: flex;
    align-items: center;
  }
  
  a.carousel-control-prev, a.carousel-control-next {
    color: $brand-primary;
    opacity: 1;
    i.fa-angle-left, i.fa-angle-right {
      font-size: 40px;
    }
  }
}

footer#footer {
	background-color: $gray-light;
  color: white;
  p {
    text-transform: uppercase;
  }
  p.footer-contact {
    a {
      color: white;
    }
  }
  .navbar-nav {
		float: none;
  }
  .nav {
  	li {
      a {
        color: white;
      }
      &.active {
	  			a {
	  				color: white;
	  				border-bottom: none;
	  			}
	  		}
  		.sub-menu {
  			display: block;
  			background-color: transparent;
  		}
  	}
	}
}

footer.email-footer-fixed {
  bottom: 0;
  box-shadow: 0  8px 8px -8px rgba(0,0,0,.75), 
                0 -8px 8px -8px rgba(0,0,0,.75);
  position: fixed;
  width: 100%;
  z-index: 100;
}

footer#footer-email {
  .mailchimp_layout {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .email {
    color: #fff;
    background: $brand-primary;
    padding-top: 20px;
    padding-bottom: 20px;
    
    input {
      margin: 0 5px 0 0 !important;
    }

    button#email-footer-close {
      color: white !important;
      cursor: pointer;
      font-size: 35px !important;
      opacity: inherit !important;
    }

    .close {
      margin: 0 !important;
    }

    h4.email-header {
      margin-right: 10px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }

    .button {
      background-color: #fff;
      color: $brand-primary;
      border-radius: 0;
      border: none;
      font-size: 1em;
      font-weight: 700;
    }

    .button:hover {
      background-color: #777;
    }

    #mce-success-response {
      color: #fff;
      width: 100%;
    }
  }
}

footer#bottom-footer {
  #footer-sponsor-section {
    .sponsor-logo {
      img.sponsor-img-logo {
        height: auto;
        max-width: 125px;
      }
    }
  }

  p.copyright {
    padding-top: 10px;
  }
}