/* ==========================================================================
   HOMEPAGE BANNERS
   ========================================================================== */
  .single-banner {
    .single-banner-img {
    	background-size: cover;
    	background-position: center center;
    	background-repeat: no-repeat;
      min-height: 30vh;
      @include media-breakpoint-up(md) { 
        border-right: 5px solid white;
      }
    }
    .single-banner-copy {
      background-color: $brand-secondary;
      color: white;
      min-height: 20vh;
      @include media-breakpoint-up(md) { 
        border-left: 5px solid white;
      }
      p {
        margin: 0;
      }
      .single-banner-copy-inner {
        @include media-breakpoint-up(md) { 
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    @include media-breakpoint-up(lg) { 
      background-attachment: fixed;
    }
  }
  .resize {
    @include media-breakpoint-up(md) { 
    	height: 45vh;
      max-height: 650px;
    }
  }
  .resize-50 {
    @include media-breakpoint-down(sm) { 
      height: auto;
    }
    @include media-breakpoint-up(md) { 
      height: 450px;
    }
  }

/* ==========================================================================
   HOME BANNERS
   ========================================================================== */
  #home-banner {
    .single-banner-copy {
      .single-banner-copy-inner {
        padding: 0 45px;
      }
    }
  }
/* ==========================================================================
   PAGE BANNERS
   ========================================================================== */
  #page-banner {
    .single-banner-img {
      min-height: 250px;
    }
    .single-banner-copy {
      position: relative;
      @include media-breakpoint-down(sm) { 
        height: auto;
      }
      .single-banner-copy-inner {
        padding: 30px 0;
        @include media-breakpoint-up(md) { 
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
        }
      }
      h1 {
        margin: 0;
        position: relative;
        @include media-breakpoint-up(md) { 
          // transform: translateX(-50%) translateY(-50%) rotate(270deg);
          transform: translateX(-50%) rotate(270deg);
        }
      }
    }
  }
  .video-play {
    border-radius: 15px !important;
    font-size: 4vw;
    left: 45%;
    padding: 5px 40px;
    position: absolute;
    top: 43%;
    @include media-breakpoint-up(md) { 
      font-size: 2vw;
    }
  }
