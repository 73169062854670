#global-donate {
	background-color: $brand-secondary;
	color: white;
	a {
		color: white;
		display: block;
		margin-bottom: 15px;
	}
	.btn {
		color: $brand-secondary;
	}
}

.widget_nav_menu,
.widget_recent_entries {
	ul,
	.menu {
		@extend .list-group;
		li {
			@extend .list-group-item;
			padding: 0;
			a {
				display: block;
				width: 100%;
				padding: 10px 15px;
				color: $gray-medium;
			}
			&.active {
				background-color: $gray-medium;
				border-color: $gray-medium;
				a {
					color: white;
				}
			}
			.post-date {
				color: $gray-light;
				display: block;
				padding: 0 15px 10px;
			}
		}
	}
}